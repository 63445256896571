* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Proxima Nova";
}

.switch > span {
  background: #611db8;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #650fa5;
  border-radius: 10px;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/Proxima\ Nova\ Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/Proxima\ Nova\ Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
